import React from 'react'
import { useStoreon } from 'storeon/react'
import { css } from 'linaria'
import { routerKey } from '@storeon/router'
import {
  getCycleText,
  getCycleTextComplete,
} from '@/utils/formatCycle'
import Footer from '../../sections/Footer'
import Table from '../../sections/Table'
import Header from '.'
import Loading from '../../pages/Loading'

const styles = css`
  @apply flex flex-col;
  height: 100vh;

  // header
  > .content {
    flex-grow: 1;
    flex-basis: 0;
    overflow-y: scroll;
  }

  .requirements {
    @apply border-solid border border-gray-300 mt-6 p-8 border-l-0 border-r-0;
    margin-left: 24px;
    margin-right: 24px;

    h3 {
      @apply text-gray-500 font-bold text-base;
    }
    p {
      @apply text-xs mt-2 text-gray-500;
    }
  }
`

const shapeProduct = (info, t) => {
  if (info.paymentLink) {
    const {
      order: {
        id, note, description, subTotal, orderMetadata,
      },
      paymentLink: {
        subscriptionInfo, linkImageUrl, linkType,
      },
    } = info
    if (linkType === 'SUBSCRIPTION') {
      return {
        name: info.paymentLink.description,
        subTotal: info.paymentLink.total,
        billingCyclesNumber: subscriptionInfo.billingCyclesNumber,
        trialCyclesNumber: subscriptionInfo.trialCyclesNumber,
        cyclesToBillBeforeAllowCancelation: subscriptionInfo.cyclesToBillBeforeAllowCancelation,
        itemId: subscriptionInfo.id,
        price: info.paymentLink.total,
        productImageUrl: linkImageUrl,
        quantity: null,
        isSubscription: true,
        billingCycleType: getCycleText(
          subscriptionInfo.billingCycleType, subscriptionInfo.cyclesToBillBeforeAllowCancelation, t, true,
        ),
        trialCycleType: getCycleText(subscriptionInfo.trialCycleType, subscriptionInfo.trialCyclesNumber, t, true),
      }
    }
    return {
      itemId: id,
      name: note || description,
      price: subTotal,
      productImageUrl: orderMetadata?.filter(
        (meta) => meta.key === 'LinkImageUrl',
      )?.[0]?.value,
      quantity: 1,
      subTotal,
      isSubscription: false,
    }
  }
  return {
    itemId: info.order.id,
    name: info.order.note || info.order.description,
    price: info.order.total,
    productImageUrl: info.order.orderMetadata?.filter(
      (meta) => meta.key === 'LinkImageUrl',
    )?.[0]?.value,
    quantity: 1,
    subTotal: info.order.subTotal,
    isSubscription: false,
  }
}

const Sidebar = ({ t }) => {
  const {
    dispatch, checkout, link, [routerKey]: route,
  } = useStoreon('checkout', 'link', routerKey)
  const orderId = link?.order?.id || link?.paymentLink?.id

  const onEditCustomData = () => {
    const paymentLinkCode = link.paymentLink.id
    const queryStringArray = []
    if (link.order.customFields.length) {
      link.order.customFields.forEach((field) => queryStringArray.push(`${field.name}=${encodeURIComponent(field.value)}`))
    }
    if (link.paymentLink.hasVariableAmount && link.order.total) {
      queryStringArray.push(`amount=${encodeURIComponent(link.order.total)}`)
    }
    window.location = `/pl/${paymentLinkCode}?${queryStringArray.join('&')}`
  }

  const { orderType } = link.order

  const isntWuhu = route.match.page !== 'wuhu'

  let products = []
  let ship = {
    price: 0,
  }

  if (orderType === 'FULL' || orderType === 'STOREFRONT') {
    products = [...link.order.orderDetails]
  } else {
    products.push(shapeProduct(link, t))
  }

  const shipmentOptionId = isntWuhu ? checkout.shipmentOptionId : link.order.shipmentOptionId

  if (shipmentOptionId) {
    let option = link.shipmentOptions.find(
      (o) => o.shipmentOptionId === shipmentOptionId,
    )
    if (!option) {
      [option] = link.shipmentOptions
    }
    const shipmentPrice = checkout?.shipmentSelected?.requiresQuote ? link?.effects?.deliveryCost : option?.baseCost
    ship = {
      itemId: 0,
      name: `${t`link.deliveryTitle`}: ${option?.name}`,
      price: shipmentPrice,
      productImageUrl: null,
      quantity: 1,
      subTotal: shipmentPrice,
    }
  }

  if (ship.price) {
    products.push(ship)
  }

  const tipTotal = isntWuhu ? link?.effects?.sessionDetail?.tip : link?.order?.tip
  if (tipTotal > 0) {
    products.push({
      itemId: 1,
      name: `${t`link.tip`} (${link?.effects?.tipOptions.find(({ amount }) => amount === tipTotal)?.percentageLabel || ''})`,
      price: tipTotal,
      productImageUrl: null,
      quantity: 1,
      subTotal: tipTotal,
    })
  }

  const orderTotal = link.effects?.sessionDetail?.total || (link.order.total + (isntWuhu ? (ship.price || 0) : 0))

  if (link.paymentLink?.linkType !== 'SUBSCRIPTION' && !link.order.id) {
    return <Loading />
  }

  return (
    <div className={styles}>
      <div className="content">
        <Header
          storeImageUrl={link.store.storeImageUrl}
          storeName={link.store.name}
          orderId={String(orderId)}
          orderType={t`link.orderTitle`}
          storeUrl={link.store.storeUrl}
        />
        {
          link.order.hasVariableAmount ? (
            <Table
              products={products}
              hasVariableAmount
              // customFields={link.order.customFields}
              canEditCustomData={false}
              canEditCustomDataFn={onEditCustomData}
              orderNotes={checkout.checkoutNote}
              onChangeOrderNotes={(value) => dispatch('checkout/save', { checkoutNote: value })}
              showNotes={isntWuhu}
            />
          ) : (
            <>
              {
                link.paymentLink?.linkType === 'SUBSCRIPTION' ? (
                  <Table
                    products={products}
                    total={parseFloat(link.paymentLink.total, 10) + (ship.price || 0)}
                    currencyLocale={link.store.config.locale}
                    currencyCode={link.store.config.currencyCode}
                    hasVariableAmount={false}
                    customFields={[]}
                    canEditCustomData={false}
                    orderNotes={checkout.checkoutNote}
                    onChangeOrderNotes={(value) => dispatch('checkout/save', { checkoutNote: value })}
                    totalSubtext={getCycleTextComplete(
                      link.paymentLink.subscriptionInfo.billingCycleType,
                      link.paymentLink.subscriptionInfo.billingCyclesNumber,
                      t,
                      true,
                    )}
                    showNotes={isntWuhu}
                  />
                ) : (
                  <>
                    <Table
                      products={products}
                      total={parseFloat(orderTotal)}
                      hasVariableAmount={false}
                      customFieldsDesc={link.paymentLink?.customFields}
                      customFields={link.order.customFields}
                      canEditCustomData={
                        link.order.customFields?.length > 0
                        && link.paymentLink?.customFields?.some((f) => f.isEditable)
                      }
                      canEditCustomDataFn={onEditCustomData}
                      orderNotes={checkout.checkoutNote}
                      onChangeOrderNotes={(value) => dispatch('checkout/save', { checkoutNote: value })}
                      showNotes={isntWuhu && route.match.page !== 'status'}
                      discounts={isntWuhu ? link.effects?.discounts : link.order.discountEffects}
                      surcharges={isntWuhu ? link.effects?.surcharges : link.order.surchargeEffects}
                    />
                  </>
                )
              }
            </>
          )
        }

        {
          link.paymentLink?.subscriptionInfo?.termsAndConditions && (
            <div className="requirements">
              <h3>{t`link.requirementsTitle`}</h3>
              <p>{link.paymentLink.subscriptionInfo.termsAndConditions}</p>
            </div>
          )
        }
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  )
}

export default Sidebar
