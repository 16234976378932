import React, { useState, Suspense } from 'react'
import { useStoreon } from 'storeon/react'
import { css } from 'linaria'
import { useTranslation } from '@hooks/I18nProvider'

import Dialog from './SidebarDialog'

const styles = css`
  @apply flex h-screen;

  .logo {
    @apply relative rounded-full flex justify-center items-center w-10 h-10 border-solid border border-gray-300;
    background: #ececec;
    
    @screen lg {
      @apply w-24 h-24 m-auto;
    }
    
    &.with-link {
      @apply cursor-pointer;
    }

    & > img {
      @apply rounded-full object-cover object-center;
      aspect-ratio: 1/1;
    }
    & > span {
      @apply block text-gray-600 font-bold text-lg;
      position: absolute;
      width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      left: 50px;
    }
  }

  .desktop-sidebar {
      @apply relative hidden lg:flex lg:flex-shrink-0 flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-gray-100;
      flex-grow: 1;
      flex-basis: 300px;
      min-width: 300px;
      z-index: 0;

      .sidebar {
        @apply flex w-full flex-col;
      }
  }

  .main-container {
    @apply flex min-w-0 flex-1 flex-col overflow-hidden;
    flex-grow: 1;
    flex-basis: 300px;
    min-width: 300px;

    .header {
      @apply flex items-center justify-between border-b border-gray-200 bg-gray-50 px-4 py-2;

      img {
        @apply h-10 w-auto;
      }

      .button-container {
        @apply cursor-pointer;

        button {
          @apply mr-3 inline-flex px-4 py-2 items-center justify-center rounded-md text-gray-500 hover:text-gray-900
            font-bold border-gray-300;
          background: #ececec;
        }

        svg {
          @apply h-4 w-4 ml-2;
        }
      }
    }
  }

  .main-content {
    @apply relative z-0 flex flex-1 overflow-hidden;
    min-height: 300px;

    > main {
      @apply relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last;

      > .content {
        @apply absolute inset-0 lg:py-6 sm:px-0 lg:px-8;
      }

      .sample {
        @apply h-full rounded-lg border-2 border-dashed border-gray-200;
      }
    }
  }

  .lg-hidden {
    @screen lg {
      @apply hidden;
    }
  }
`

const TwoColumns = ({
  Sidebar, Component, props, ...ex
}) => {
  const { link } = useStoreon('link')
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <Suspense fallback={<div />}>
      <div className={styles}>
        <Dialog
          show={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
        >
          <Sidebar {...props} t={t} />
        </Dialog>
        <div className="desktop-sidebar">
          <Sidebar {...props} t={t} />
        </div>
        <div className="main-container">
          <div className="lg-hidden">
            <div className="header">
              <div
                className={`logo ${link.store.storeUrl ? 'with-link' : ''}`}
                onClick={() => {
                  if (link.store.storeUrl) {
                    window.location.replace(link.store.storeUrl || '')
                  }
                }}
              >
                <img src={link.store.storeImageUrl || '/images/n1co-icon.svg'} />
                <span>{link.store.name}</span>
              </div>
              <div className="button-container">
                {
                  (link.order.id || link.paymentLink.id) && (
                    <button
                      type="button"
                      onClick={() => setSidebarOpen(true)}
                    >
                      {t`link.viewDetail`}
                      { /* link.order.total ? formatCurrency(link.order.total) : '' */ }
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                      </svg>
                    </button>
                  )
                }
              </div>
            </div>
          </div>
          <div className="main-content">
            <main>
              <div className="content">
                {Component ? <Component {...props} t={t} /> : null}
              </div>
            </main>
          </div>
        </div>
      </div>
    </Suspense>
  )
}

export default TwoColumns
