import * as amplitude from '@amplitude/analytics-browser'
import E from '@hooks/useTrackEvents/events'

const {
  H4B_AMPLITUDE_API_KEY: AMPLITUDE_API_KEY,
  H4B_AMPLITUDE_ENABLED: AMPLITUDE_ENABLED,
} = h4benv

const useTrackEvents = () => {
  const isTrackingTokenInitialized = localStorage.getItem('tracking-token')
  const isTrackingEventsAvailable = AMPLITUDE_ENABLED === 'true' && isTrackingTokenInitialized

  const initializeTrackingSession = (userId) => {
    if (AMPLITUDE_ENABLED === 'true') {
      amplitude.init(AMPLITUDE_API_KEY, userId, {
        defaultTracking: false,
      })
      localStorage.setItem('tracking-token', Date.now().toString())
    }
  }

  const trackEvent = (eventName, options = {}) => {
    if (isTrackingEventsAvailable) {
      try {
        amplitude.track(eventName, { ...options, source: 'WEB' })
      } catch (error) {
        console.error(error)
      }
    }
  }

  const trackLoginAttempt = (userId, loginMethod) => {
    if (AMPLITUDE_ENABLED === 'true') {
      amplitude.reset()
      amplitude.init(AMPLITUDE_API_KEY, userId, {
        defaultTracking: false,
      })
      localStorage.setItem('tracking-token', Date.now().toString())
      trackEvent(E.LOGIN_ATTEMPT, {
        method: loginMethod,
      })
    }
  }

  const closeTrackingSession = () => {
    localStorage.removeItem('tracking-token')
  }

  return {
    initializeTrackingSession,
    closeTrackingSession,
    trackEvent,
    trackLoginAttempt,
  }
}

export default useTrackEvents
